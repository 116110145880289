




















import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import RERService from "@/services/request/RERService";
import {PersonSex, ResidencyStatus} from "@/constants/PersonConstants";
import PersonDTO from "@/dto/person/PersonDTO";
import EmployeeRER from "@/components/request/rer/EmployeeRER.vue";
import {REREmployeeStatus} from "@/constants/request/RERConstants";
import Short_RER_DTO from "@/dto/request/rer/Short_RER_DTO";
import { processError } from "@/utils/ComponentUtils";
import RouteNames from "@/router/RouteNames";
import { ShortInformationEmployeeDTO } from "@/dto/request/rer/ShortInformationEmployeeDTO";

const AppModule = namespace("App");

@Component({
  computed: {
    REREmployeeStatus() {
      return REREmployeeStatus
    },
    PersonDTO() {
      return PersonDTO
    },
    PersonSex() {
      return PersonSex
    },
    ResidencyStatus() {
      return ResidencyStatus
    }
  },
  components: {
    EmployeeRER,
  }
})
export default class RER extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  request: Short_RER_DTO | null = null;

  mounted(): void {
    this.loadRequest();
  }

  get id(): number {
    return Number.parseInt(this.$route.params.id);
  }

  loadRequest(): void {
    this.startLoading();
    RERService.getById(this.id).then(
      ok => {
        this.request = ok.data;
        this.stopLoading();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  goToEmployee(employee: ShortInformationEmployeeDTO): void {
    this.$router.push({name: RouteNames.RER_EMPLOYEE, params: { id: `${employee.id}` }});
  }

}
